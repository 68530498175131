<template>
  <collapse-card :model-value="true">
    <template #title>
      <span class="mr-2 text-sm font-medium">Safety Documents</span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <h6 class="font-semibold text-black text-sm">
          Files to Upload
          <span class="text-primary-red">*</span>
        </h6>
        <input
          ref="fileUploadInput"
          type="file"
          class="hidden"
          multiple
          @change.prevent="addNewFiles"
        >
        <div class="flex mb-3">
          <div class="rounded-sm border border-custom-gray-8 mr-3 w-full max-w-lg min-h-40 px-3 py-2 flex flex-wrap">
            <div
              v-for="(file, fileIndex) in newFilesList"
              :key="'new-file' + fileIndex"
              class="bg-custom-gray-5 text-black flex flex-col justify-center px-2 py-1 rounded mr-1 mb-1"
            >
              <div class="flex flex-col justify-center flex-grow">
                <img
                  v-if="file.stagedFileUrl && file.file.type && ['image/jpeg','image/png','image/gif'].includes(file.file.type)"
                  :src="file.stagedFileUrl"
                  class="mx-auto h-12 w-auto max-w-xs"
                >
                <img
                  v-else
                  src="@/assets/images/upload/file.png"
                  class="mx-auto h-8 w-auto max-w-xs"
                >
              </div>

              <div class="mx-auto mt-1 w-32 flex justify-center">
                <div class="mr-1 truncate text-xs flex-grow">
                  {{ file.filename }}
                </div>
                <base-svg
                  class="h-3.5 w-3.5 text-primary-red inline-block hover:text-custom-gray-7 cursor-pointer align-middle flex-shrink-0"
                  src="icons/cross.svg"
                  tag="div"
                  @click="removeNewFile(fileIndex)"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <base-button
              class="py-1 mb-3"
              variant="btn-primary"
              text="Add Files"
              :is-loading="__submissionLoadingTracker.isLoadingSafetyDocs"
              type="button"
              @click="openFileSelector()"
            />
            <base-button
              variant="btn-link"
              text="Clear All"
              type="button"
              @click="clearAllNewFiles()"
            />
          </div>
        </div>
        <div class="flex mb-6 justify-between w-full">
          <div v-if="__submissionLoadingTracker.isLoadingSafetyDocs">
            <base-svg
              class="h-4 w-4 mr-1 text-primary-red inline-block"
              src="icons/circleSpinner.svg"
              tag="span"
            />
            Starting file upload ...
          </div>
          <base-button
            v-else
            class="mr-4 py-1 whitespace-nowrap"
            text="Upload Files"
            type="button"
            variant="btn-primary"
            :disabled="newFilesList.length === 0"
            @click="initAttachmentUpload()"
          />
          <base-button
            v-if="uploadedFilesList.length > 0"
            variant="btn-primary ml-4"
            text="Refresh All"
            type="button"
            :is-loading="isRefreshLoading"
            @click="refreshAllAttachments()"
          />
        </div>

        <!-- uploaded files section -->
        <div
          v-if="isFetchingUploadedFiles"
          class="w-full text-center mx-auto p-2"
        >
          <base-svg
            class="h-4 w-4 mr-1 text-primary-red inline-block"
            src="icons/circleSpinner.svg"
            tag="span"
          />
          Loading ...
        </div>
        <div
          v-else
          class="flex flex-wrap reverse-zoom"
        >
          <div
            v-for="(uploadedFile, uFIndex) in uploadedFilesList"
            :key="'uploadedFile' + uFIndex"
            class="uploadedFile.isDelete === 'Y' && submissionForm?.isAdminEdit ? 'w-1/2 py-4 pr-4 relative':'py-4 pr-4 relative'"
          >
            <div class="flex">
              <div
                v-if="uploadedFile.isDelete === 'N' || allowInProgressDelete(uploadedFile)"
                class="flex flex-col mr-4">
                <div
                  class="h-28 w-28 p-1 relative"
                >
                  <a
                    class="zoom"
                    :href="thumbPathSrcMap(uploadedFile)"
                    target="_blank"
                  >
                    <!-- img1 is for zoom -->
                    <img
                      class="m-auto max-h-full max-w-full img1"
                      :src="thumbPathSrcMap(uploadedFile)"
                    >
                    <!-- img2 will remain there. -->
                    <img
                      class="m-auto max-h-full max-w-full img2"
                      :src="thumbPathSrcMap(uploadedFile)"
                    >
                  </a>
                </div>
                <div
                  class="flex justify-center my-2"
                >
                  <base-svg
                    v-if="selectedUploadAsset?.assetId == uploadedFile.assetId && uploadedFile.isUploaded === 'Y'"
                    class="h-4 w-4 mx-2 inline-block text-primary-red"
                    src="icons/circleSpinner.svg"
                    tag="span"
                  />
                  <base-svg
                    v-if="selectedUploadAsset?.assetId !== uploadedFile.assetId && uploadedFile.isUploaded === 'Y'"
                    class="h-4 w-4 mx-2 inline-block text-primary-red cursor-pointer"
                    src="icons/download.svg"
                    tag="span"
                    @click="downloadUploadedFile(uploadedFile)"
                  />
                  <!-- v-if="selectedUploadAsset?.assetId !== uploadedFile.assetId" -->
                  <base-svg
                    v-if="!isAlreadyDeleted(uploadedFile) && uploadedFile.isDelete === 'N' && uploadedAssetDeleteLogicFieldState(uploadedFile, uFIndex) !== INVISIBLE && (uploadedFile.allowDelete || allowInProgressDelete(uploadedFile) ) && canDeleteInProgress"
                    class="h-4 w-4 mx-2 inline-block text-primary-red cursor-pointer"
                    src="icons/cross.svg"
                    tag="span"
                    @click="confirmUploadedAssetDlt(uploadedFile)"
                  />
                  <base-svg
                    v-if="isAlreadyDeleted(uploadedFile) || allowInProgressDelete(uploadedFile)"
                    class="h-4 w-4 mx-2 inline-block text-primary-red cursor-pointer"
                    src="icons/arrowsClockwise.svg"
                    tag="span"
                    @click="restoreDeletedFile(uploadedFile)"
                  />
                </div>
              </div>

              <div
                v-if="uploadedFile.isDelete === 'N' || allowInProgressDelete(uploadedFile)"
                class="flex flex-col flex-grow"
              >
                <div class="text-black font-semibold text-sm mt-2 mb-2 max-w-sm bg-red-100 p-1 rounded-md truncate">
                  {{ uploadedFile.filename }}
                </div>
                <div class="flex mb-1 justify-between">
                  <div class="text-black text-sm mb-1 mr-40 whitespace-nowrap">
                    <span class="mr-3">Uploaded On:</span>
                    <span class="font-medium">{{ formatDate(uploadedFile.creationDate, 'MM/DD/YYYY', true) }}</span>
                  </div>
                </div>
                <div class="flex mb-1 justify-between">
                  <div class="text-black text-sm mr-20 whitespace-nowrap">
                    <span class="mr-3">Uploaded By:</span>
                    <span class="font-medium">{{ uploadedFile.createdUser }}</span>
                  </div>
                </div>
                <div class="text-black text-sm mb-1">
                  <span class="mr-3">Uploaded At:</span>
                  <span class="font-medium">{{ getSubmissionStageName(uploadedFile.stage) }}</span>
                </div>

                <div
                  v-if="isAlreadyDeleted(uploadedFile) || allowInProgressDelete(uploadedFile)"
                  class="mt-1"
                >
                  <span class="px-2 py-1 rounded-md font-light font-mono text-xs bg-red-600 text-white">Deleted</span>
                </div>
                <div
                  v-if="isFileErrored(uploadedFile) && !isAlreadyDeleted(uploadedFile) && canDeleteInProgress"
                  class="mt-1"
                >
                  <span class="px-2 py-1 rounded-md  text-xs text-red-600">Please Delete and Re-Upload</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </collapse-card>
  <base-modal
    v-model="showDeleteUploadedAsset"
    modal-title="Confirm to Delete Attachment"
    @hidden="resetDeleteUploadedAssetModal()"
  >
    <template #modalBody>
      <div class="px-4 py-3">
        <div class="font-sm text-center mb-2">
          Are you sure to delete <strong>{{ selectedUploadAsset.filename }}</strong> ?
        </div>
        <div class="text-center mt-2">
          <base-button
            class="mr-3"
            variant="btn-primary"
            text="Yes"
            :is-loading="isDltFileLoading"
            @click="deleteUploadedFile()"
          />
          <base-button
            variant="btn-link"
            text="No"
            @click="resetDeleteUploadedAssetModal()"
          />
        </div>
      </div>
    </template>
  </base-modal>
  <teleport to="body">
    <div
      v-if="openImgDiv"
      class="bg-custom-enlarge-img fixed inset-0 z-50 flex items-center justify-center flex-col"
    >
      <base-svg
        class="mx-2 inline-block text-primary-red bg-white h-6 w-6 pl-1 mb-3.5 enlarge-img-shadow rounded-full"
        src="icons/cross.svg"
        :svg-attributes="{
          class: 'mt-1 mr-1'
        }"
        tag="span"
        @click="closeDiv()"
      />
      <img
        :src="thumbPathSrcMap(openImageValuePath)"
      >
    </div>
  </teleport>
</template>

<script>
import { computed, defineAsyncComponent, ref, reactive, watch, onMounted } from 'vue';
import useSubmissionForm from './submissionForm.js';
import useSubmissionLoadTracker from '@/components/brand-assurance-submission-form/submissionLoadTracker.js';

import { formatDate, formatFilename } from '@/helpers/util';
import { useStore } from 'vuex';
import moment from 'moment';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { SUCCESS, WARNING, ATTACHEMENT_DOWNLOADED_SUCCESSFULLY, ATTACHEMENT_DOWNLOAD_FAILED, ERROR } from '@/constants/alerts.js';
import useUploadStatusPopUp from './uploadStatusPopUp';

import useUserAuth from '@/hooks/userAuth.js';
import { EDITABLE, INVISIBLE, LICENSEE_STEP, BAC_REVIEW_STEP } from '@/constants/submissions.js';
import { BAC_SUPERVISOR_ROLE, BA_ADMIN_ROLE, LICENSEE_ROLE, BAC_REVIEWER_ROLE } from '@/constants/ba-roles.js';

export default {
    name: 'UploadDocuments',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    setup () {
        const store = useStore();
        const { userRoles } = useUserAuth();
        const { notificationsStack } = useToastNotifications();
        const { fetchUploadFiles, pushFilesForAssetPlaceholders, isFetchingUploadedFiles } = useUploadStatusPopUp();

        const { submissionForm, submissionFormMode, updateSubmissionForm } = useSubmissionForm();
        const userId = computed(() => store.getters['auth/getUserId']);
        const isClaimedByUser = () => {
            return submissionForm.assignUser === userId.value || submissionFormMode.value === 'NEW';
        };

        // file upload logic
        const fileUploadInput = ref(null);
        const newFilesList = ref([]);

        onMounted(async () => {
            await fetchUploadFiles();
        });

        // NOTE: THE FOLLOWING WATCHER IS NOT NEEDED HERE .. SINCE UPLOAD DOC SECTION ALSO HAS THE SAME WATCHER
        // .. SO THE FILES WILL BE UPDATED FROM THERE
        // watch(
        //   () => submissionForm.submissionId,
        //   async (__new, __old) => {
        //     if (__new) await fetchUploadFiles()
        //   }
        // )

        const openImgDiv = ref(false);
        const openImageValuePath = ref(null);
        const showDiv = (val) => {
            openImageValuePath.value = val;
            openImgDiv.value = true;
        };

        const closeDiv = () => {
            openImgDiv.value = false;
        };

        const canDeleteInProgress = [LICENSEE_STEP, BAC_REVIEW_STEP].includes(submissionForm.currentStepName) && [LICENSEE_ROLE, BAC_REVIEWER_ROLE].some(role => userRoles.value.includes(role));
        const allowInProgressDelete = (uploadedFile) => uploadedFile.isDelete === 'Y' && submissionForm?.isAdminEdit;

        const addNewFiles = (event) => {
            const { files } = event.target;
            const MAX_ALLOWED_FILE_SIZE = (95 * 1024 * 1024 * 1024); // 95GB
            for (let i = 0; i < files.length; i++) {

                let filename = formatFilename(files[i].name);
                const specialChars = /[`!@#$%^&*()+=[\]{};':"\\|,<>/?~]/;
                const nonEnglishChar = /[^\x00-\x7F]+/;

                const nonEnglishCharExists = nonEnglishChar.test(filename);
                const specialCharsExists = specialChars.test(filename);

                if (specialCharsExists || nonEnglishCharExists) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: `File name can contain only Alphabets, Numbers, Period, Underscore and Hyphens: ${filename}`
                    });
                } else if (files[i].size && files[i].size > MAX_ALLOWED_FILE_SIZE) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: `File size exceeds the maximum allowed size: ${filename}`
                    });
                } else if (uploadedFilesList?.value.some(tmpFile => formatFilename(tmpFile.filename).toLowerCase() === filename.toLowerCase() && tmpFile.isDelete === 'N')) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: `Attachment already exists with filename: ${filename}`
                    });
                } else if (files[i].size < 1) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: `File size cannot be zero for : ${filename}`
                    });
                } else if (newFilesList.value.some(newFile => formatFilename(newFile.file.name).toLowerCase() === filename.toLowerCase())) {
                    const tmpMsg = `Skipping duplicate file .. ${filename}`;
                    console.log(tmpMsg);
                    notificationsStack.value.push({
                        type: WARNING,
                        message: tmpMsg
                    });
                } else {
                    const stagedFileUrl = URL.createObjectURL(files[i]);

                    if (!filename.includes('.')) filename = `${filename}.bin`;

                    newFilesList.value.push({
                        file: files[i],
                        assetType: null,
                        filename,
                        stagedFileUrl
                    });
                }
            }
            event.target.value = null;
        };
        const removeNewFile = (index) => {
            newFilesList.value.splice(index, 1);
        };
        const openFileSelector = () => {
            fileUploadInput.value.click();
        };
        const clearAllNewFiles = () => {
            newFilesList.value = [];
        };

        const uploadDataFromStore = computed(() => store.getters['upload/getUploadData']);

        // uploaded files logic
        const { updateSubmissionLoadingTracker } = useSubmissionLoadTracker();
        const __submissionLoadingTracker = reactive({ isLoadingSafetyDocs: false });

        const initAttachmentUpload = async () => {
            try {
                __submissionLoadingTracker.isLoadingSafetyDocs = true;
                const filePaths = newFilesList.value.map(e => {
                    e.filename = e.filename.toLowerCase();
                    return `${e.stagedFileUrl}/${e.filename}`;
                });
                const fileSizes = newFilesList.value.map(e => e.file.size);

                await store.dispatch('upload/uploadFiles', {
                    payload: {
                        fileSizes,
                        filePaths,
                        attachmentData: {
                            submissionId: submissionForm.submissionId,
                            stage: submissionForm.currentStage || 1,
                            step: submissionForm.currentStep || 1,
                            assetType: 'SAFETY'
                        }
                    }
                });

                if (!submissionForm.assets) submissionForm.assets = [];
                uploadDataFromStore.value.forEach((file, i) => {
                    submissionForm.assets.push({
                        assetId: file.objectId,
                        filename: file.filename,
                        assetType: 'SAFETY'
                    });
                });
                await pushFilesForAssetPlaceholders(newFilesList.value);
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Problem in creating the attachment.'
                });
            } finally {
                __submissionLoadingTracker.isLoadingSafetyDocs = false;
                clearAllNewFiles();
            }
        };

        const isRefreshLoading = ref(false);
        const refreshAllAttachments = async () => {
            try {
                __submissionLoadingTracker.isLoadingSafetyDocs = true;
                isRefreshLoading.value = true;
                await fetchUploadFiles();
            } catch (err) {
                console.error(err);
            } finally {
                __submissionLoadingTracker.isLoadingSafetyDocs = false;
                isRefreshLoading.value = false;
            }
        };

        const isDltFileLoading = ref(false);
        const deleteUploadedFile = async (id) => {
            try {
                const tmp = submissionForm.attachmentsToBeDeleted || [];
                updateSubmissionForm({ attachmentsToBeDeleted: [...tmp, selectedUploadAsset.value.assetId] });
            } finally {
                showDeleteUploadedAsset.value = false;
            }
        };
        const restoreDeletedFile = async (file) => {
            try {
                const tmpUpdates = submissionForm.attachmentsToBeUpdated || [];
                const tmp = uploadedFilesList.value.filter(a => a.isDelete === 'Y' && a.assetId === file.assetId);
                tmp.forEach((asset) => {
                    if (asset.assetId === file.assetId) {
                        updateSubmissionForm({ attachmentsToBeUpdated:[...tmpUpdates, asset.assetId] });
                        asset.isDelete = 'N';
                    }
                });
                updateSubmissionForm({ attachmentsToBeDeleted: submissionForm.attachmentsToBeDeleted.filter(e => e !== file.assetId) });
            } finally {
                showDeleteUploadedAsset.value = false;
            }
        };

        const fetchedUploadFiles = computed(() => store.getters['upload/getUploadedFiles']);
        const uploadedFilesList = computed(() =>
            fetchedUploadFiles.value && fetchedUploadFiles.value.length
                ? fetchedUploadFiles.value.filter(asset => asset.assetType === 'SAFETY') : []
        );

        watch(
            () => fetchedUploadFiles.value,
            () => {
                uploadedFilesList.value = fetchedUploadFiles.value && fetchedUploadFiles.value.length
                    ? fetchedUploadFiles.value.filter(asset => asset.assetType === 'SAFETY') : [];
            }
        );

        const downloadUploadedFile = async (asset) => {
            try {
                selectedUploadAsset.value = asset;
                const getPresignedURL = await store.dispatch('upload/getDownloadAttachmentURL', {
                    params: {
                        // id: asset.assetId,

                        // DIRECT-S3-DL
                        id: asset.fileId,
                        filename: asset.filename,

                        isSubmissionAttachment: true
                    }
                });

                if (!getPresignedURL?.presignedURL) throw new Error('Download URL is not available.');
                const windowOpened = window.open(getPresignedURL.presignedURL);
                if (!windowOpened) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                    });
                } else {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ATTACHEMENT_DOWNLOADED_SUCCESSFULLY
                    });
                }
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ATTACHEMENT_DOWNLOAD_FAILED}. ${err}`
                });
            } finally {
                selectedUploadAsset.value = null;
            }
        };

        // confirm delete search modal logic
        const selectedUploadAsset = ref(null);
        const confirmUploadedAssetDlt = (selectedAsset) => {
            selectedUploadAsset.value = selectedAsset;
            setDeleteUploadedAssetModalVisibility(true);
        };

        const showDeleteUploadedAsset = ref(false);
        const setDeleteUploadedAssetModalVisibility = (visibility) => {
            showDeleteUploadedAsset.value = visibility;
        };
        const resetDeleteUploadedAssetModal = () => {
            selectedUploadAsset.value = null;
            setDeleteUploadedAssetModalVisibility(false);
        };

        const thumbPathSrcMap = (file) => {
            // const isNewFile = uploadedFilesList?.value.some(tmpFile => formatFilename(tmpFile.filename).toLowerCase() === filename.toLowerCase());
            // const hours = moment().diff(moment(creationDate), 'hours');
            if (file.isUploaded !== 'Y') {
                if (isFileErrored(file)) { // && !isNewFile
                    return require('@/assets/images/upload/uploadFileError.png'); // Error Image
                } else {
                    return require('@/assets/images/upload/uploadFileImage.png'); // In progress Image
                }
            }

            const fileExt = file?.filename?.split('.').pop();
            if (fileExt) {
                switch (fileExt.toLowerCase()) {
                case 'jpg':
                case 'jpeg':
                case 'heic':
                case 'png':
                case 'psd':
                case 'eps':
                case 'gif':
                case 'tif':
                case 'tiff':
                    if (file.thumbnailUrl) return file.thumbnailUrl;
                    else return require('@/assets/images/upload/file.png');
                case 'pdf':
                    if (file.thumbnailUrl) return file.thumbnailUrl;
                    return require('@/assets/images/upload/pdf.png');
                case 'zip':
                    return require('@/assets/images/upload/zip.png');
                case 'mov':
                case 'mp4':
                case 'm4v':
                case 'avi':
                    return require('@/assets/images/upload/video.png');
                case 'aif':
                case 'aiff':
                case 'wav':
                case 'mp3':
                    return require('@/assets/images/upload/audio.png');
                default:
                    return require('@/assets/images/upload/file.png');
                }
            }
            return require('@/assets/images/upload/file.png');
        };

        const submissionStages = computed(() => store.getters['baSubmissions/getSubmissionStages']);
        const getSubmissionStageName = (stageKey) => {
            if (!submissionStages?.value) return '';
            const tmp = submissionStages.value.find(s => s.stageKey === stageKey);
            return tmp?.stageName || '[ Processing... ]';
        };

        watch(
            __submissionLoadingTracker,
            () => {
                updateSubmissionLoadingTracker({ ...__submissionLoadingTracker });
            },
            { deep: true, immediate: true }
        );

        // delete submission uploaded asset logic
        const isFileErrored = (file) => {
            // const isNewFile = uploadedFilesList?.value.some(tmpFile => formatFilename(tmpFile.filename).toLowerCase() === file.filename.toLowerCase());
            const hours = moment().diff(moment(file.creationDate), 'hours');
            return ((file.errorMessage?.length && file.isUploaded === 'N') || (file.isUploaded === 'N' && hours > 1));
        };

        const isAlreadyDeleted = (file) => submissionForm.attachmentsToBeDeleted.includes(file.assetId);
        const uploadedAssetDeleteLogicFieldState = (file, i) => {
            if (!isClaimedByUser()) {
                file.allowDelete = false;
                return INVISIBLE;
            } else if ([BAC_SUPERVISOR_ROLE, BA_ADMIN_ROLE].some(role => userRoles.value.includes(role))) {
                file.allowDelete = true;
                return EDITABLE;
            } else if ((submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode === 'NEW') && isClaimedByUser()) {
                if (file.step === submissionForm.currentStep && file.stage === submissionForm.currentStage) {
                    file.allowDelete = true;
                } else {
                    file.allowDelete = false;
                }
                return EDITABLE;
            } else {
                file.allowDelete = true;
                return EDITABLE;
            }
        };

        return {
            showDiv,
            openImgDiv,
            openImageValuePath,
            closeDiv,
            formatDate,
            // upload file
            removeNewFile,
            openFileSelector,
            fileUploadInput,
            newFilesList,
            addNewFiles,
            clearAllNewFiles,
            // uploaded files
            deleteUploadedFile,
            isDltFileLoading,
            uploadedFilesList,
            initAttachmentUpload,
            downloadUploadedFile,
            // progress,
            // progressIndex,
            resetDeleteUploadedAssetModal,
            confirmUploadedAssetDlt,
            selectedUploadAsset,
            showDeleteUploadedAsset,
            refreshAllAttachments,
            thumbPathSrcMap,
            isFetchingUploadedFiles,
            // uploadedFilesListAfterAction,
            getSubmissionStageName,
            isClaimedByUser,
            __submissionLoadingTracker,
            submissionForm,
            uploadedAssetDeleteLogicFieldState,
            isAlreadyDeleted,
            allowInProgressDelete,
            canDeleteInProgress,
            restoreDeletedFile,
            isFileErrored
        };
    }
};
</script>

<style>
.zoom:hover img.img1 {
  min-width: 100px;
  transform: scale(4.5);
  position: absolute;
  right: -1515px;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  pointer-events: none;
  /* dressing */
  background: white;
  padding: 0.5px;
  border: 1px solid red;
  border-radius: 2px;
  box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 25%);
}

.img2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.zoom:hover img.img2 {
  opacity: 1;
}

.reverse-zoom .relative:nth-child(even) .zoom:hover img.img1 {
  right: inherit;
  left: -500px;
}

</style>
